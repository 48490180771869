/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { graphql } from "gatsby"

import TopNavigation from "../components/TopNavigation"
import BlogPost from "../components/BlogPost"
// import BlogPostCard from "../components/BlogPostCard"
import Layout from "../layout"

function Blog(props) {
  const { data } = props
  const posts = data.allGhostPost.edges

  return (
    <Layout title="Amooto Blog">
      <TopNavigation />
      {posts.map(({ node }) => {
        return (
          <Box mt={[3, 4, 5]} mb={(2, 4, 4)} key={node.id}>
            <BlogPost post={node} toTitlePath={node.slug} />
          </Box>
        )
      })}
    </Layout>
  )
}

export default Blog

// TODO: use tags to filter, filter: { tags: { elemMatch: { name: { eq: "mission" } } } }
export const postQuery = graphql`
  query {
    allGhostPost(
      sort: { fields: published_at, order: DESC }
      filter: { tags: { elemMatch: { name: { in: "#amooto" } } } }
    ) {
      edges {
        node {
          slug
          id
          title
          excerpt
          reading_time
          tags {
            name
          }
          primary_tag {
            name
          }
          published_at(formatString: "DD MMMM YYYY")
          authors {
            id
            name
          }
          feature_image
          html
        }
      }
    }
  }
`

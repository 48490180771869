/** @jsx jsx */
import { Link } from "gatsby"
import { jsx, Flex, Box, Image, Heading } from "theme-ui"

const BlogPost = ({ post, toTitlePath }) => {
  return (
    <Flex sx={{ justifyContent: "center" }}>
      <Box mx="auto" my={0} sx={{ maxWidth: 700, minWidth: 0, width: "100%" }}>
        {post.feature_image ? (
          <Image
            sx={{ width: "80vw" }}
            src={post.feature_image}
            alt={post.title}
          />
        ) : null}
        <Box sx={{ textAlign: "center" }}>
          <Box>
            <time>{post.published_at}</time>
          </Box>
          {toTitlePath ? (
            <Link to={toTitlePath}>
              <Title title={post.title} />
            </Link>
          ) : (
            <Title title={post.title} />
          )}
        </Box>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
      </Box>
    </Flex>
  )
}

export default BlogPost

const Title = props => {
  const { title } = props
  return (
    <Heading
      sx={{
        fontSize: [30, 36, 42],
        fontWeight: 400,
        color: "primary",
        lineHeight: 1.4,
        maxWidth: "90%",
      }}
      as="h1"
      mx="auto"
      mt={3}
      mb={4}
    >
      {title}
    </Heading>
  )
}
